import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import { useCookies } from "vue3-cookies"
import { V1_ROUTER_LIST_ALL } from "@/api/const/router"
import { apiPost, apiGetNoParam, apiGet } from "@/api/index"
const _ = require('lodash');

const { cookies } = useCookies()
let routes: any = []
await apiGetNoParam(V1_ROUTER_LIST_ALL).then((res) => {
	let response: any = res;
	let value = response.data.data;
	for (let i = 0; i < value.length; i++) {
		let item = value[i]
		let dataRouter = {
			path: item.path,
			name: item.name,
			redirect: item.redirect,
			layout: item.layout,
			component: () => import('../views' +`${item.component}`),
			meta: item.meta,
		}
		Object.fromEntries(Object.entries(dataRouter).filter(([key, value]) => value !== null));
		let other = _.omitBy(dataRouter, (v: any) => v == null);
		routes.push(other)
	}
});

function addLayoutToRoute( route: any, parentLayout = "default" )
{
	route.meta = route.meta || {} ;
	route.meta.layout = route.layout || parentLayout ;
	
	if( route.children )
	{
		route.children = route.children.map( ( childRoute: any ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
	}
	return route ;
}

routes = routes.map( ( route: any ) => addLayoutToRoute( route ) ) ;

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior (to, from, savedPosition) {
		if ( to.hash ) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: 'smooth',
		}
	}
})
export default router
