<template>
  <!-- Layout Header ( Navbar ) -->
  <!-- <el-affix :offset="1"> -->
  <div>
    <div style="border-bottom: var(--accent-color) 1px solid;"
      class="container-fluid position-relative d-flex align-items-center justify-content-between"
    >
      <div>
        <SwitchLang></SwitchLang>
      </div>
      <div>
        <!-- <UserInfor></UserInfor> -->
      </div>
    </div>
    <a-anchor>
      <div class="bg-header">
        <a-layout-header
          id="header"
          class="header d-flex align-items-center sticky-top"
        >
          <div
            class="container-fluid position-relative d-flex align-items-center justify-content-between"
          >
            <div class="logo d-flex align-items-center me-xl-0" @click="goHome">
              <el-image
                style="width: 250px"
                :src="companys.logo"
                fit="contain"
              />
            </div>
            <nav id="navmenu" class="navmenu">
              <MenuWebsite :data-menu="menuHeader"></MenuWebsite>
              <div class="nav-mobile" for="menu-btn">
                <label class="btn menu-btn" @click="showDrawer">
                  <i class="fas fa-bars"></i
                ></label>
              </div>
            </nav>
            <!-- <a class="btn-getstarted" href="#about">Get Started</a> -->
          </div>
        </a-layout-header>
        <!-- / Layout Header ( Navbar ) -->
        <Drawer placement="left" :show-form="showMenu" @closePopup="closePopup">
          <template #content>
            <div>
              <MenuMobile :data-menu="menuHeader"></MenuMobile>
            </div>
          </template>
        </Drawer>
      </div>
    </a-anchor>
  </div>
  <!-- </el-affix> -->
</template>

<script setup lang="ts">
import { ref, onMounted, watch, h, computed } from "vue";
import { MenuProps } from "ant-design-vue";
import { V1_REDIS_GET_GROUP } from "@/api/const/redis";
import { apiPost, apiGetNoParam, apiGet } from "@/api/index";
import SwitchLang from "@/components/element/lang/SwitchLang.vue";
import Drawer from "@/components/ant/drawer/Drawer.vue";
import MenuMobile from "@/components/Headers/default/menu/MenuMobile.vue";
import MenuWebsite from "@/components/Headers/default/menu/MenuWebsite.vue";
import commons from "@/utils/Common";
import router from "@/router";
import UserInfor from '@/components/Headers/comn/UserInfor.vue'
import store from "@/store";
import Utils from "@/utils/Utils";
onMounted(async () => {
  await informCompany();
  await loadData();
});
const menuHeader = ref([]);
const items = ref([]);
const showMenu = ref(false);
const showDrawer = () => {
  showMenu.value = true;
};
const companys = ref({});
const informCompany = async () => {
  let dataCompany = commons.getCokiesCompany();
  if (dataCompany != null && dataCompany != "null") {
    companys.value = JSON.parse(JSON.stringify(dataCompany));
  } else {
    await company();
  }
};

const company = async () => {
  await apiGetNoParam(
    V1_REDIS_GET_GROUP + "?key=20241221company01&group=20241221company01"
  ).then((res) => {
    let response: any = res;
    let dataCompanys = response.data.data;
    commons.setCokiesCompany(dataCompanys[0]);
    companys.value = JSON.parse(JSON.stringify(dataCompanys[0]));
    store.dispatch('setLoadLogin', Utils.randomText(5))
  });
};

const loadData = async () => {
  await apiGetNoParam(
    V1_REDIS_GET_GROUP + "?key=menuheadertop1&group=menuheadertop1"
  ).then((res) => {
    let response: any = res;
    menuHeader.value = response.data.data;
    // for(let i = 0; i < menuHeader.value.length; i++) {
    //     let item = menuHeader.value[i]
    //     let subMenuData:any = []
    //     if(item.subMenu.length > 0) {
    //       let subMenuData2:any = []
    //         for(let j = 0; j < item.subMenu.length; j++ ) {
    //           let itemSub = item.subMenu[j]
    //           // if(itemSub.subMenu.length > 0) {
    //           //   for(let k = 0; k < itemSub.subMenu.length; k++ ) {
    //           //     let itemSub2 = itemSub.subMenu[k]
    //           //     subMenuData2.push({
    //           //       label: itemSub2.linkLabel,
    //           //       key: itemSub2.linkTo,
    //           //       title: itemSub2.linkLabel,
    //           //     })
    //           //   }
    //           // }
    //           subMenuData.push({
    //             label: itemSub.linkLabel,
    //             key: itemSub.linkTo,
    //             title: itemSub.linkLabel,
    //             // children: subMenuData2
    //           })
    //         }
    //     }
    //     items.value.push({
    //       key: item.linkTo,
    //       label: item.linkLabel,
    //       title: item.linkLabel,
    //       children: subMenuData
    //     })
    // }
  });
};

const isLoadLogin = computed(() => {
    return store.getters.isLoadLogin;
  });
watch(isLoadLogin, async (newValue, oldValue) => {
  await informCompany()
});

const closePopup = (val: any) => {
  showMenu.value = val;
};

const goHome = () => {
  router.push({ path: "./" });
};

</script>
<style>
.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: var(--accent-color);
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after,
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-selected::after,
.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected:hover,
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-selected:hover {
  /* border-bottom-width: 2px;
  border-bottom-color: var(--accent-color); */
  border-bottom-width: 2px;
  border-bottom-color: var(--accent-color);
}
</style>

<style lang="scss" scoped>
.logo:hover {
  cursor: pointer;
}

#menu-btn:checked ~ .nav-links-menu {
  left: 0%;
}

#menu-btn:checked ~ .btn.menu-btn {
  display: none;
}

#close-btn:checked ~ .btn.menu-btn {
  display: block;
}

.boder-bottom {
  border-bottom: 1px dashed #ccc;
}

// @media screen and (max-width: 1199px) {

// }
// @media screen and (min-width: 1200) {
//   .nav-mobile {
//     display: none !important
//   }
// }

@media only screen and (min-width: 360px) and (max-width: 1199px) {
  .nav-mobile {
    display: block !important;
  }

  .nav-website {
    display: none !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 2000px) {
  .nav-mobile {
    display: none !important;
  }

  .nav-website {
    display: block !important;
  }
}

// .layout-default .header {
//   margin: 10px;
// }
// .bg-header {
//   background-color: #fff !important
// }
</style>
