<template>
  <!-- <div class="social-fix">
        <ButtonAdmin></ButtonAdmin>
        <ButtonQRCode></ButtonQRCode>
        <Zalo></Zalo>

    </div> -->
  <div>
    <a-float-button-group shape="circle" style="right: 24px; bottom: 80px">
      <a-tooltip
        placement="left"
        key="#108ee9"
        title="Quản trị website"
        color="#108ee9"
      >
        <a-float-button v-show="show" class="bg-icon-right" type="primary">
          <template #icon>
            <a style="color: #fff" @click="qrCode">
              <i class="fa-solid fa-users-gear"></i>
            </a>
          </template>
        </a-float-button>
      </a-tooltip>
      <!-- <a-tooltip
        placement="left"
        key="#108ee9"
        title="Liên hệ tổng đài: 1900 98 98 29"
        color="#108ee9"
      >
        <a-float-button class="bg-icon-right" type="primary">
          <template #icon>
            <a style="color: #fff" href="tel:1900989829">
              <i class="fa-solid fa-headset"></i>
            </a>
          </template>
        </a-float-button>
      </a-tooltip> -->
      <!-- <a-tooltip
        placement="left"
        key="#108ee9"
        title="Hệ thống phân phối"
        color="#108ee9"
      >
        <a-float-button class="bg-icon-right" type="primary">
          <template #icon>
            <router-link style="color: #fff" to="sell" class="">
              <i class="fa-solid fa-location-dot"></i>
            </router-link>
          </template>
        </a-float-button>
      </a-tooltip>
      <a-tooltip
        placement="left"
        key="#108ee9"
        title="Đăng kí bảo hành"
        color="#108ee9"
      >
        <a-float-button class="bg-icon-right" type="primary">
          <template #icon>
            <a
              style="color: #fff"
              href="https://viglacera.vn/dang-ky-bao-hanh"
              target="_blank"
            >
              <i class="fa-solid fa-file-signature"></i>
            </a>
          </template>
        </a-float-button>
      </a-tooltip> -->
      <!-- <a-tooltip
        placement="left"
        key="#108ee9"
        title="Liên hệ qua Zalo"
        color="#108ee9"
      >
        <a-float-button class="bg-icon-right" type="primary">
          <template #icon>
            <a href="https://zalo.me/2490999282525015829" target="_blank"
              ><img
                src="data:image/webp;base64,UklGRmwCAABXRUJQVlA4TF8CAAAvMYAFENVIkiRJkaT+/4N3OiHCD5lVsz0vcFlhZmZGW2aMgAQAYNko09m2bdu2bftl27bvnrZt27Zt+yYgeHJEpzq7ePGizvWkt6N6PXr16NWjR3UUdt5xLOv/cDbKfTSb3Oo9FX+dHmc9vrNV8kLx/XulmBbmLe66Q7B8hRKwTheTnPEOhapnKAnrLk7+7EIouoYScTj0W1QnQprIPBWGhl5pG9pEqCuCqtOughxXvwZ/azWEhmQhJe3CllW64U00hnMmZUnABXszi3Ct6w8dZEEnXzPmXEMevpA0Dv7zLHZf6wr2oux7TPWQnOvywKOyIPGd5yxHs3UGexEboDQWdRsoqzrHIA3ATRTQcpdryi+AbPJD2DobICPT7j0QfjpfkZ62LQJ9DpEALxuG3Sn0DmqzjqHoNXYgfG1nF+Tkimz3qBlAP4H6HqLWtq+BWoeq242ATrYBdhqBLpd0FUiDChgTLasgKoYohazzQyi63RwI/A2wQ0n/3F7qArYtefSm16tHr7qnwf9B+9CqfRGKbpMGQt52RIDWdTbLCI+iEKnwRlczIBoab+dWGRCVw9aqIAO5FpwdJ/UvQRbOcsr7rAidsKxK34ZtpDUMoBeAoNf5pAfvx41d30vX/Az2YnziGfN0sG4nuVqpVzWipLAbZ44D0R7ZOXE+9Lz1eJb7ldZOZo0PR8LYzpsJ+soZjZ76maMx9EazuRzLuUUT3QDbIZpajzwP3ArzeFd+GZw6gWw7bK5O1r/yrithcogjJZ9BVobO9cp5XHKSqsJNSLydYQMOJHQlH/CGFgVNwAcZAA=="
                style="width: 100%"
            /></a>
          </template>
        </a-float-button>
      </a-tooltip> -->
      <!-- <a-tooltip
        placement="left"
        key="#108ee9"
        title="Liên hệ ngay"
        color="#108ee9"
      >
        <a-float-button class="bg-icon-right" type="primary">
          <template #icon>
            <a
              style="color: #fff"
              href="https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2FThietbivesinhViglacera"
              target="_blank"
            >
              <i class="fa-brands fa-facebook-messenger"></i>
            </a>
          </template>
        </a-float-button>
      </a-tooltip> -->
      <a-back-top class="bg-icon-right" type="primary" />
    </a-float-button-group>
  </div>
</template>
<script lang="ts" setup>
import { watch, reactive, ref, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { USER } from "@/const/cookie";
import { useCookies } from "vue3-cookies";
import store from "@/store/index";
const { cookies } = useCookies();
const router = useRouter();
const qrCode = () => {
  router.push({ path: "/dashboard" });
};
const show = ref(false);
onMounted(async () => {
    await loadData()
});
const isLoadLogin = computed(() => {
  return store.getters.isLoadLogin;
});
watch(isLoadLogin, async (newValue, oldValue) => {
  await loadData()
});

const loadData = () => {
    let user = cookies.get(USER);
  if (user != null) {
    let roles = user.role;
    if (roles == 9) {
      show.value = true;
    }
  } else {
    show.value = false;
  }
}
</script>
<style type="css">
.ant-float-btn-primary .ant-float-btn-body {
    background-color: var(--accent-color);
    transition: background-color 0.2s;
}
.social-fix {
  position: fixed;
  bottom: 92px;
  right: 30px;
  z-index: 99;
}

.social-fix a {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-top: 10px;
  border-radius: 100px;
  background: var(--accent-color) center center no-repeat;
  background-size: 21px;
  position: relative;
}
.social-fix a i {
  font-size: 24px;
  color: #fff;
}
.social-fix a i:hover {
  font-size: 20px;
}
.social-fix a:hover {
  cursor: pointer;
  text-decoration: none !important;
}
.social-fix a span:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  top: 50%;
  right: -5px;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}
.social-fix a span {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  position: absolute;
  top: 50%;
  right: 110%;
  font-size: 12px;
  background: #000;
  color: #fff;
  border-radius: 5px;
  white-space: nowrap;
  padding: 5px 10px;
  margin-top: -15px;
}
btn-admin {
  position: fixed;
  top: 25%;
  right: 0;
  z-index: 999;
}

.btn-admin {
  cursor: pointer;
}

.btn-admin {
  background: linear-gradient(-30deg, #00334d 100%, #006699 0%);
  padding: 5px 10px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #d4e0f7;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
</style>
