<template>
  <a-drawer
    :open="showForm"
    class="custom-class"
    root-class-name="root-class-name"
    :title="title"
    :placement="placement"
    @close="afterOpenChange"
  >
    <div>
      <slot name="content"></slot>
    </div>
  </a-drawer>
</template>
<script lang="ts" setup>
import { ref } from "vue";
const emits = defineEmits(["closePopup", "submitPopup"]);
interface props {
  showForm: any;
  placement: any;
  title: any;
}
const propsData = withDefaults(defineProps<props>(), {
  showForm: false,
  placement: "right",
  title: "",
});
const open = ref<boolean>(false);

const afterOpenChange = (bool: boolean) => {
  emits("closePopup", false);
};
</script>
